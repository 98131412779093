import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Loader from '../../components/loader';
import styles from './documents.module.scss';
import { selectLegalDocuments } from '../../store/selectors/entities';

const Documents = ({ pageContext }) => {
  const { titleId, documentId } = pageContext;
  console.log('debug-documentId', documentId);
  const [fullSize, setFullSize] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const intl = useIntl();
  const legalDocuments = useSelector(selectLegalDocuments);
  console.log('%c debug-legalDocuments', 'font-weight:bold; background-color:yellow; color:navy; padding:5px;',
    legalDocuments);
  const url = legalDocuments?.find(lD => lD.type === documentId)?.url;
  console.log('debug-url', url);
  const title = intl.formatMessage({ id: titleId });
  const toggleFullSize = (e) => {
    e.preventDefault();
    setFullSize(!fullSize);
  };

  return (
    <>
      <SEO title={title} />
      <Layout hideHeader fluid>
        <h1 className={styles.title}>{title}</h1>
        <Loader isLoading={loading} isSmall={false} />
        <div
          className={clsx(styles.wrapper, { [styles.fullSize]: fullSize })}
          onDoubleClick={(e) => e.preventDefault()}
        >
          <div
            tabIndex="-1"
            role="presentation"
            className={styles.widthToggle}
            onClick={toggleFullSize}
          />
          {url && (
            <iframe
              onLoad={() => setLoading(false)}
              title={title}
              width="100%"
              height="100%"
              src={url}
              frameBorder="0"
              style={{ position: 'relative' }}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

Documents.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  pageContext: PropTypes.shape({
    titleId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    docPath: PropTypes.string,
    docUrl: PropTypes.string,
  }).isRequired,
};

export default Documents;
